import { SYNC_PORTAL_SUCCESS } from '../actions';

export const portalPresentationReducer = (state, action) => {
    const tile_type = 'presentations';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};

export const portalEstimateReducer = (state, action) => {
    const tile_type = 'estimates';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};

export const portalSalesOrderReducer = (state, action) => {
    const tile_type = 'sales_orders';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};

export const portalDepositReducer = (state, action) => {
    const tile_type = 'deposit_invoices';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};

export const portalInvoiceReducer = (state, action) => {
    const tile_type = 'invoices';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};

export const portalOrderStatusReducer = (state, action) => {
    const tile_type = 'order_status';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};

export const portalProofReducer = (state, action) => {
    const tile_type = 'proofs';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};

export const portalFeedbackReducer = (state, action) => {
    const tile_type = 'feedbacks';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};

export const portalUnusedReducer = (state, action) => {
    const tile_type = 'unused';
    switch (action.type) {
        case SYNC_PORTAL_SUCCESS:
            if (tile_type === action.payload.tile_type) {
                return action.payload.entity;
            }
            break;
    }
    return state;
};