import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import api from '../middleware/api';
import thunk from 'redux-thunk';

import {
    portalPresentationReducer,
    portalEstimateReducer,
    portalSalesOrderReducer,
    portalDepositReducer,
    portalInvoiceReducer,
    portalOrderStatusReducer,
    portalProofReducer,
    portalFeedbackReducer,
    portalUnusedReducer} from '../reducers/portal';
import { portalCommentReducer } from '../reducers/comment';
import displayReducer from '../reducers/display';
import { clientPortalReducer, } from './configureClientPortalStore';
import { window } from '../global';

var initialState = typeof(initialState) === 'undefined' ? window.initialState : initialState;
const defaultEntitiesState = initialState && initialState.entities ? initialState.entities : {};

const entitiesReducer = (state = defaultEntitiesState, action) => {
    const new_state = Object.assign({}, state, {
        presentations: portalPresentationReducer(state.presentations, action),
        estimates: portalEstimateReducer(state.estimates, action),
        sales_orders: portalSalesOrderReducer(state.sales_orders, action),
        deposit_invoices: portalDepositReducer(state.deposit_invoices, action),
        order_status: portalOrderStatusReducer(state.order_status, action),
        proofs: portalProofReducer(state.proofs, action),
        invoices: portalInvoiceReducer(state.invoices, action),
        feedbacks: portalFeedbackReducer(state.feedbacks, action),
        unused: portalUnusedReducer(state.unused, action),
        messages: portalCommentReducer(state.messages, action),
    });
    return new_state;
};

const identityReducer = (state = initialState.identity, action) => {
    return state;
};

export const reducers = {
    display: displayReducer,
    identity: identityReducer,
    entities: entitiesReducer,
    client_portal: clientPortalReducer,
};

export default function configurePortalStore(initialState) {
    const store = createStore(
        combineReducers(reducers),
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}

