import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Theme } from '@commonsku/styles';
import { ToastContainer } from 'react-toastify';
import Iframe from './Iframe';
import TruncatedText from './TruncatedText';
import TextArea from './TextArea';
import ReorderOrder from './client-portal/ReorderOrder';
import { themeOptions } from './client-portal/utils';

import { addPortalComment, deletePortalComment } from '../actions/comment';

import { syncMessages } from '../actions/message';
import { syncTiles } from '../actions';

import { oauth } from '../utils';

import { getAbsoluteUrl, parseMysqlDate, formatFriendlyDateTime, getUserImageSrc } from '../utils';
import { createGlobalStyle } from './helpers';

const GlobalStyle = createGlobalStyle(p => `
.commonsku-styles-select__value-container {
    height: 37px;
}
`);

class ClientPortal extends Component {
    constructor(props) {
        super(props);

        let all_done = 0;
        if (props.presentations.icon === 'done' &&
            props.estimates.icon === 'done' &&
            props.sales_orders.icon === 'done' &&
            props.deposit_invoices.icon === 'done' &&
            props.order_status.icon === 'done' &&
            props.proofs.icon === 'done' &&
            props.invoices.icon === 'done' &&
            props.feedbacks.icon === 'done') {
            all_done = 1;
        }

        this.state = {
            'client_page_clicks_count': {},
            'client_page_view_count': 0,
            'shown_order': false,
            show_reorder: false,

            'await_download': 0,
            'editing': 0,
            'warning': '',
            'files': [],
            'name': '',
            'email': '',
            'publisher_text': '',
            'messages': props.messages,
            //'show_name_email_popup': !props.user_id ? 1 : 0,
            'show_name_email_popup': 0,
            'form_popup': {
                show: 0,
                url: '',
                form_type: '',
                form_id: '',
                form_number: '',
            },
            'multi_popup': {
                show: 0,
                forms: [],
                tile_name: '',
            },
            'show_order_status_popup': 0,
            'email_popup': {
                show: 0,
                email: {},
            },
            'unused': all_done ? 0 : props.unused,
            'tiles': [
                {
                    'key': 'PRESENTATION',
                    'name': 'Presentation',
                    'icon': props.presentations.icon,
                    'img_src': '/images/portal/presentation.jpg',
                    'forms': Object.values(props.presentations).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'ESTIMATE',
                    'name': 'Estimate',
                    'icon': props.estimates.icon,
                    'img_src': '/images/portal/estimate.jpg',
                    'forms': Object.values(props.estimates).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'SALES ORDER',
                    'name': 'Sales Order',
                    'icon': props.sales_orders.icon,
                    'img_src': '/images/portal/salesorder.jpg',
                    'forms': Object.values(props.sales_orders).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'DEPOSIT',
                    'name': 'Deposit Invoice',
                    'icon': props.deposit_invoices.icon,
                    'img_src': '/images/portal/deposit.jpg',
                    'forms': Object.values(props.deposit_invoices).reduce((o, p) => { if (p.form_number) {o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'ORDER STATUS',
                    'name': 'Order Status',
                    'icon': props.order_status.icon,
                    'img_src': '/images/portal/orderstatus.jpg',
                    'forms': {},
                },
                {
                    'key': 'PROOF',
                    'name': 'Proof Approval',
                    'icon': props.proofs.icon,
                    'img_src': '/images/portal/proofapproval.jpg',
                    'forms': Object.values(props.proofs).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'INVOICE',
                    'name': 'Invoice',
                    'icon': props.invoices.icon,
                    'img_src': '/images/portal/invoice.jpg',
                    'forms': Object.values(props.invoices).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'FEEDBACK',
                    'name': 'Feedback',
                    'icon': props.feedbacks.icon,
                    'img_src': '/images/portal/feedback.jpg',
                    'forms': Object.values(props.feedbacks).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
            ],
            'all_done': all_done
        };

        _.bindAll(
            this,
            ['handleChange',
                'handleAddPortalComment',
                'showFormPopup',
                'renderFormPopup',
                'closeFormPopup',
                'renderMultiPopup',
                'renderFormLine',
                'renderUnusedTile',
                'renderMessages',
                'renderEmail',
                'showOrderStatusPopup',
                'renderOrderStatusPopup',
                'renderShipped',
                'renderShippedItems',
                'renderShippedInfo',
                'showEmailPopup',
                'renderEmailPopup',
                'renderPresentationComment',
                'renderClientNote',
                'renderComment',
                'renderNameEmailPopup',
                'startRefreshFeed',
                'handleDownloadForm',
                'download',
                'validateEmail']
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            tiles: [
                {
                    'key': 'PRESENTATION',
                    'name': 'Presentation',
                    'icon': nextProps.presentations.icon,
                    'img_src': '/images/portal/presentation.jpg',
                    'forms': Object.values(nextProps.presentations).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'ESTIMATE',
                    'name': 'Estimate',
                    'icon': nextProps.estimates.icon,
                    'img_src': '/images/portal/estimate.jpg',
                    'forms': Object.values(nextProps.estimates).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'SALES ORDER',
                    'name': 'Sales Order',
                    'icon': nextProps.sales_orders.icon,
                    'img_src': '/images/portal/salesorder.jpg',
                    'forms': Object.values(nextProps.sales_orders).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'DEPOSIT',
                    'name': 'Deposit Invoice',
                    'icon': nextProps.deposit_invoices.icon,
                    'img_src': '/images/portal/deposit.jpg',
                    'forms': Object.values(nextProps.deposit_invoices).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'ORDER STATUS',
                    'name': 'Order Status',
                    'icon': nextProps.order_status.icon,
                    'img_src': '/images/portal/orderstatus.jpg',
                    'forms': {},
                },
                {
                    'key': 'PROOF',
                    'name': 'Proof Approval',
                    'icon': nextProps.proofs.icon,
                    'img_src': '/images/portal/proofapproval.jpg',
                    'forms': Object.values(nextProps.proofs).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'INVOICE',
                    'name': 'Invoice',
                    'icon': nextProps.invoices.icon,
                    'img_src': '/images/portal/invoice.jpg',
                    'forms': Object.values(nextProps.invoices).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                },
                {
                    'key': 'FEEDBACK',
                    'name': 'Feedback',
                    'icon': nextProps.feedbacks.icon,
                    'img_src': '/images/portal/feedback.jpg',
                    'forms': Object.values(nextProps.feedbacks).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {}),
                }],
            messages: Object.values(nextProps.messages).filter(m => m.parent_id === null || m.parent_id === m.message_id).sort((a, b) => b.latest_update - a.latest_update),
        });

        if (nextProps.presentations.icon === 'done' &&
            nextProps.estimates.icon === 'done' &&
            nextProps.sales_orders.icon === 'done' &&
            nextProps.deposit_invoices.icon === 'done' &&
            nextProps.order_status.icon === 'done' &&
            nextProps.proofs.icon === 'done' &&
            nextProps.invoices.icon === 'done' &&
            nextProps.feedbacks.icon === 'done') {
            this.setState({ all_done: 1, unused: 0 });
        }
    }

    componentDidMount() {
        this.startRefreshFeed();
        this.getPageActionsCount();
        // this.refreshPageActionsCount();

        /* if (!this.props.user_id) {
            let client_name = window.localStorage.getItem('commonsku_client_name');
            let client_email = window.localStorage.getItem('commonsku_client_email');
            if (client_name) {
                this.setState({ name: client_name });
            }
            if (client_email) {
                this.setState({ email: client_email });
            }
        } */

        const tile = this.getReorderFormPopupTileByQuery(this.props, this.state);
        if (!this.state.shown_order && tile) {
            this.showFormPopup(tile.key, tile.forms, tile.type);
            this.setState({shown_order: true});
        }
    }

    /**
     * Get Form popup tile by url query params
     * @param {object} props props
     * @param {object} state state
     * @returns {null | {key: string, forms: object, type: string}}
     */
    getReorderFormPopupTileByQuery(props=this.props, state=this.state) {
        const so = state.tiles.filter(t =>
            t.key === 'SALES ORDER'
            && t.icon && t.icon !== 'hidden'
        );
        const canShowFormPopup = props.query_params
            ? ['y', 'yes'].includes(props.query_params.reorder) && so.length > 0
            : false;
        return canShowFormPopup ? so[0] : null;
    }

    componentDidUpdate(prevProps, prevState) {
        const tile = this.getReorderFormPopupTileByQuery(prevProps, prevState);
        if (!prevState.shown_order && tile) {
            this.showFormPopup(tile.key, tile.forms, tile.type);
            this.setState({shown_order: true});
        }
    }

    /**
     * Checks if there is a user logged into the page
     * Logged in: Distributor
     * Not logged in: Client
     *
     * returns true if user not logged in, so is a client
     * else false => distributor
     */
    checkIsClient() {
        if (!this.props.user_id) return true;
        return false;
    }

    refreshPageActionsCount() {
        this.interval = setInterval(() => {
            this.getPageActionsCount();
        }, 10000);
    }

    getPageActionsCount() {
        if (this.checkIsClient()) return;

        let click_counts = {};
        let view_count = 0;
        const data = {public: 1,job_id: this.props.job.job_id, type: 'PORTAL'};
        oauth('GET', 'page-view/stats', data).then(({ json }) => {
            json.page_views.forEach(val => {
                if (val.page_view_subtype == 'PORTAL') {
                    view_count = val.num;
                } else {
                    click_counts[val.page_view_subtype] = val.num;
                }
                // if (val.activity_type == 'click') click_counts[val.page_view_subtype] = val.num;
                // if (val.activity_type == 'view') view_count = val.num;
            });
            this.setState({ client_page_clicks_count: click_counts, client_page_view_count: view_count });
        });
    }

    updatePageClickCount(action_type) {
        if (!this.checkIsClient()) return; // only update for clients
        oauth('POST', 'page-view', {
            public: 1,
            job_id: this.props.job.job_id,
            type: 'PORTAL',
            subtype: action_type,
            activity_type: 'click',
        }).then(({ json }) => {});
    }

    renderPageViewCount() {
        if (this.checkIsClient() || this.state.client_page_view_count == 0) return;

        return (
            <span style={{
                textAlign: "right",
                fontSize: "medium",
                float: "right",
                marginRight: "15px",
                marginTop: "5px",
            }}>
                <i className="fi-eye" style={{fontSize: "x-large", verticalAlign: "middle"}}></i>
                <span> {this.state.client_page_view_count}</span>
            </span>
        );
    }

    renderPageClickCount(type) {
        if (this.checkIsClient() || !_.get(this.state, ['client_page_clicks_count', type])) return;
        return (
            <span style={{
                textAlign: "right",
                fontSize: "small",
                float: "right",
                marginRight: "35px",
                // verticalAlign: "middle",
                marginTop: "5px",
            }}>
                <i className="fi-eye" style={{fontSize: "x-large", verticalAlign: "middle"}}></i>
                <span style={{verticalAlign: "middle"}}> {this.state.client_page_clicks_count[type]}</span>
            </span>
        );
    }

    startRefreshFeed() {
        const job_id = this.props.job.job_id;
        const _onSyncMessages = this.props.onSyncMessages;
        const _onSyncTiles = this.props.onSyncTiles;

        this.interval = setInterval(function () {
            const data = {
                job_id: job_id,
                public: 1,
            };
            return oauth('GET', 'newsfeed/portal', data).then(({ json }) => {
                _onSyncMessages(json.portal.messages);
                _onSyncTiles(json.portal);
            });
        }, 300000);
    }

    renderNameEmailPopup() {
        return (
            <div className="popup-dark">
                <a href="" className="close-popup" onClick={e => { e.preventDefault(); this.closeFormPopup('show_name_email_popup'); }} style={{ display: 'none' }}>×</a>
                <div className="popup-frame grid-x" style={{ overflow: 'hidden !important' }}>
                    <div className="small-12 medium-4"></div>
                    <div className="pop-info small-12 medium-4">
                        <div className="pop-tbl">
                            <div className="pop-docs grid-x">
                                <h6 className="medium-12">Please let us know who you are.</h6>
                                {this.state.warning != '' ? <p className="medium-12" style={{ color: 'red' }}>{this.state.warning}</p> : null}
                                <div className="medium-6 cell" id="write">
                                    <input placeholder="Name" value={this.state.name} onChange={e => this.handleChange(e.target.value, 'name')} />
                                </div>
                                <div className="medium-6 cell" id="write">
                                    <input placeholder="Email" value={this.state.email} onChange={e => this.handleChange(e.target.value, 'email')} />
                                </div>
                                <button className="button medium-12" style={{ fontSize: '16px' }} onClick={e => { e.preventDefault(); this.closeFormPopup('show_name_email_popup'); }}>Ok</button>
                            </div>
                        </div>
                    </div>
                    <div className="small-12 medium-4"></div>
                </div>
            </div>
        );
    }

    handleChange(value, type) {
        if ((type === 'name' || type === 'email') && !this.props.user_id) {
            window.localStorage.setItem(`commonsku_client_${type}`, value);
        }

        this.setState({ [type]: value });
    }

    download(filename, text) {
        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        pom.setAttribute('download', filename);

        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            pom.dispatchEvent(event);
        } else {
            pom.click();
        }
    }

    handleDownloadForm() {
        this.setState({ await_download: 1 });

        let controller = 'order';
        if (this.state.form_popup.form_type === 'DEPOSIT') {
            controller = 'deposit';
        }

        oauth('GET', `${controller}/${this.state.form_popup.form_id}`, { "email": true }).then(({ json }) => {
            this.download('download.html', json.html_email);
            this.setState({ await_download: 0 });
        });
    }

    handleAddPortalComment(job_id, commenter_id) {
        if (commenter_id) {
            this.props.onAddPortalComment({
                parent_id: job_id,
                commenter_id: commenter_id,
                text: this.state.publisher_text,
            }, 0);
            this.setState({
                files: [],
                publisher_text: ''
            });
        } else {
            if (!this.props.user_id) {
                let client_name = window.localStorage.getItem('commonsku_client_name');
                let client_email = window.localStorage.getItem('commonsku_client_email');
                if (client_name) {
                    this.setState({ name: client_name });
                }
                if (client_email) {
                    this.setState({ email: client_email });
                }
            }

            if (this.state.name != '' && this.state.email != '') {
                this.props.onAddPortalComment({
                    parent_id: job_id,
                    commenter_id: commenter_id,
                    text: this.state.publisher_text,
                    commenter_name: this.state.name,
                    commenter_email: this.state.email,
                }, 1);
                this.setState({
                    files: [],
                    publisher_text: ''
                });
            } else {
                //Should have name and email in state
                return;
            }
        }
    }

    returnAvatar(m) {
        if (m.details_type === 'EMAIL') {
            return getUserImageSrc(m.email.email_from_actor);
        }

        return getUserImageSrc(m.actor ?? {});
    }

    returnText(text) {
        const filtered_text = _.filter(
            (text)
                .replace(/(href=['"])(?!https?:)/, "$1/")
                .replace(/<a /, '<a target="_blank" ')
                .split(),
            (line) => {
                return !!line;
            }
        ).join(' ');

        return filtered_text;
    }

    showFormPopup(form_type, forms, tile_name, url, form_id, form_number) {
        if (url) {
            this.setState({ 'form_popup': { show: 1, url: url, form_type: form_type, form_id: form_id, form_number: form_number }, 'multi_popup': { show: 0, forms: [], tile_name: '' } });
        }

        if (forms) {
            if (Object.keys(forms).length === 1) {
                this.setState({ 'form_popup': { show: 1, url: forms[Object.keys(forms)[0]]['public_url'], form_type: form_type, form_id: forms[Object.keys(forms)[0]]['form_id'], form_number: forms[Object.keys(forms)[0]]['form_number'] } });
                //Todo: put the spinner on presentation page without breaking html converts
                if (form_type === 'PRESENTATION') {
                    this.setState({ await_download: 1 });
                    let _this = this;
                    setTimeout(function () {
                        _this.setState({ await_download: 0 });
                    }, 1500);
                }
            } else {
                this.setState({ 'multi_popup': { show: 1, forms: Object.values(forms), tile_name: tile_name, form_type: form_type } });
            }
        }
    }

    renderReOrderForm() {
        return (
            <Theme theme={themeOptions}>
                <GlobalStyle />
                <ReorderOrder
                    order_id={this.state.form_popup.form_id}
                    client_id={this.props.job.account_id}
                    contact_email={this.props.query_params.email}
                    onCancel={e => this.setState(s => ({ show_reorder: !s.show_reorder, }))}
                />
                <ToastContainer
                    autoClose={3000}
                    hideProgressBar={true}
                />
            </Theme>
        );
    }

    renderFormPopup() {
        const download_url = this.state.form_popup.form_type === 'DEPOSIT' ?
            `/pdf.php?deposit_id=${this.state.form_popup.form_id}&form_type=${this.state.form_popup.form_type}&form_number=${this.state.form_popup.form_number}`
            : `/pdf.php?order_id=${this.state.form_popup.form_id}&form_type=${this.state.form_popup.form_type}&form_number=${this.state.form_popup.form_number}`;
        const reorder_tile = this.state.form_popup.show == 1 && this.state.form_popup.form_type == 'SALES ORDER' && this.getReorderFormPopupTileByQuery();

        return (
            <div className="popup-dark">
                <a href="" className="close-popup" onClick={e => { e.preventDefault(); this.closeFormPopup('form_popup'); }}>×</a>
                {this.state.form_popup.form_type != '' && this.state.form_popup.form_id != '' && this.state.form_popup.form_type != 'FEEDBACK' && this.state.form_popup.form_type != 'PROOF' ?
                    <a className="button form-download" href={download_url} target="_blank"><span><img className="download-img" src="/images/portal/download.png" /></span>Download</a>
                    : null}
                {reorder_tile
                    ? <a className="button form-reorder" style={this.state.show_reorder ? {} : {background: themeOptions.colors.cta, color: '#fff'}} onClick={e => {
                        e.preventDefault();
                        this.setState(s => ({ show_reorder: !s.show_reorder, }));
                    }} target="_blank">{this.state.show_reorder ? 'Order Details' : 'Reorder'}</a>
                    : null}
                {this.state.await_download ? <img src='/images/gears.gif' className='download-spinner' /> : null}
                <div className="popup-frame" style={this.state.show_reorder ? {overflow: 'auto',} : {}}>
                    {this.state.show_reorder && reorder_tile ? this.renderReOrderForm() : <Iframe src={`${this.state.form_popup.url}&portal=true`} white_background={this.props.tenant.resku_form == 0 ? true : false} />}
                </div>
            </div>
        );
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    closeFormPopup(params) {
        if (params === 'form_popup') {
            this.setState({ 'form_popup': { show: 0, url: '', form_type: '', form_id: '', form_number: '' }, show_reorder: false, });
        } else if (params === 'multi_popup') {
            this.setState({ 'multi_popup': { show: 0, forms: [], tile_name: '' } });
        } else if (params === 'show_order_status_popup') {
            this.setState({ 'show_order_status_popup': 0 });
        } else if (params === 'email_popup') {
            this.setState({ 'email_popup': { show: 0, email: {} } });
        } else if (params === 'show_name_email_popup') {
            if (this.state.name != '' && this.state.email != '' && this.validateEmail(this.state.email)) {
                this.setState({ 'show_name_email_popup': 0 });
                window.localStorage.setItem('commonsku_client_name', this.state.name);
                window.localStorage.setItem('commonsku_client_email', this.state.email);
            } else {
                if (this.state.name == '' || !(/([^\s] *)/.test(this.state.name))) {
                    this.setState({ warning: 'Empty Name.' });
                    return;
                }
                if (this.state.email == '') {
                    this.setState({ warning: 'Empty Email.' });
                    return;
                }
                if (!this.validateEmail(this.state.email)) {
                    this.setState({ warning: 'Email not valid.' });
                    return;
                }
            }
        }
    }

    renderMultiPopup() {
        return (
            <div className="popup-dark">
                <a href="" className="close-popup" onClick={e => { e.preventDefault(); this.closeFormPopup('multi_popup'); }}>×</a>
                <div className="popup-frame">
                    <div className="pop-info">
                        <div className="pop-tbl">
                            <div className="pop-docs">
                                <h2>{this.state.multi_popup.tile_name}s</h2>
                                <div className="grid-x">
                                    {this.state.multi_popup.forms.map((f, idx) =>
                                        this.renderFormLine(f, idx)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderFormLine(f, idx) {
        return [
            <div className="document small-3" onClick={e => { e.preventDefault(); this.showFormPopup(f.form_type, null, null, f.public_url, f.form_id, f.form_number); }}>
                <a>{`${this.state.multi_popup.tile_name} #${f.form_number}`}</a>
            </div>,
            <div className="document small-3" onClick={e => { e.preventDefault(); this.showFormPopup(f.form_type, null, null, f.public_url, f.form_id, f.form_number); }}>
                <p>{f.currency_symbol}{f.total_total}</p>
            </div>,
            <div className="document small-3" onClick={e => { e.preventDefault(); this.showFormPopup(f.form_type, null, null, f.public_url, f.form_id, f.form_number); }}>
                <p>{f.paid == 1 ? 'Paid' : 'Unpaid'}</p>
            </div>,
            <div className="date small-3" onClick={e => { e.preventDefault(); this.showFormPopup(f.form_type, null, null, f.public_url, f.form_id, f.form_number); }}>
                <p>{f.date_created}</p>
            </div>
        ];
    }

    renderUnusedTile() {
        let tiles = [];
        for (let i = 0; i < this.state.unused; i++) {
            tiles.push(
                <div key={i} className="cell small-4" id="unused">
                    <div className="unused-tile">
                    </div>
                </div>
            );
        }
        return tiles;
    }

    renderMessages() {
        return (
            this.state.messages.map(m =>
                m.details_type === 'EMAIL' ?
                    this.renderEmail(m)
                    : m.details_type === 'PRESENTATION-COMMENT' ?
                        this.renderPresentationComment(m)
                        : (m.details_type === 'NOTE' || m.details_type === 'PAYMENT') ?
                            this.renderClientNote(m)
                            : m.details_type === 'PROOF-COMMENT' || m.details_type === 'PORTAL-COMMENT' ?
                                this.renderComment(m)
                                : null
            )
        );
    }

    getEmailFrom(email) {
        const {
            sender,
            email_from,
        } = email || {};
        const {
            contact_email, contact_first_name, contact_last_name
        } = sender || {};
        // sender.contact_email is deprecate, email.email_from is the real "from"
        return contact_email == email_from
            ? `${contact_first_name} ${contact_last_name} [${email.email_from}]`
            : email_from
            ;
    }

    renderEmail(m) {
        const avatar = this.returnAvatar(m);
        const text = this.returnText(m.text || m.message_text || '');

        return (
            <div key={m.message_id} className="msg" id="email">
                <h3 style={{ wordWrap: 'break-word' }}><a><span className="name">{this.getEmailFrom(m.email)} </span></a> sent an email to {m.email.email_to_recipients}</h3>
                <i>{formatFriendlyDateTime(m.latest_update)}</i><br />
                <div className="info">
                    <h3>Subject: {m.subject}</h3>
                    <p><TruncatedText html={text} limit={300} /></p>
                </div>
                <a className="view-btn" onClick={e => { e.preventDefault(); this.showEmailPopup(m.email); }}>View Email</a>
            </div>
        );
    }

    showOrderStatusPopup() {
        this.setState({ show_order_status_popup: 1 });
    }

    renderOrderStatusPopup() {
        return (
            <div className="popup-dark">
                <a href="" className="close-popup" onClick={e => { e.preventDefault(); this.closeFormPopup('show_order_status_popup'); }}>×</a>
                <div className="popup-frame">
                    <div className="pop-info">
                        <div className="pop-tbl">
                            <div className="pop-status">
                                <h2>
                                    <img src="/images/portal/sml-orderstatus.jpg" />Order Status
                                </h2>
                                <ol className="cd-breadcrumb triangle">
                                    <li id="stp-1"><h2>Proofing</h2></li>
                                    <li id="stp-2"><h2>In Production</h2></li>
                                    <li id="stp-3"><h2>Shipped</h2></li>
                                </ol>
                                {this.props.order_status.proofing.items ?
                                    <div className="grid-x order-status" style={this.props.order_status.proofing.items ? { borderTop: '2px solid #e6eef4' } : null}>
                                        <div className="cell small-4" id="proofing">
                                            {Object.values(this.props.order_status.proofing.items).map(p =>
                                                <h3>{p.product_name}</h3>
                                            )}
                                            <p>In-Hands Date: {parseMysqlDate(this.props.order_status.proofing.date_inhandsdate)}</p>
                                        </div>
                                    </div>
                                    : null}
                                {this.props.order_status.production.items ?
                                    <div className="grid-x order-status" style={this.props.order_status.production.items ? { borderTop: '2px solid #e6eef4' } : null}>
                                        <div className="cell small-4" id="production">
                                            {Object.values(this.props.order_status.production.items).map(p =>
                                                <h3>{p.product_name}</h3>
                                            )}
                                            <p>In-Hands Date: {parseMysqlDate(this.props.order_status.production.date_inhandsdate)}</p>
                                        </div>
                                    </div>
                                    : null}
                                <div className="grid-x order-status" style={Object.values(this.props.order_status.shipped).length > 0 ? { borderTop: '2px solid #e6eef4' } : null}>
                                    {Object.values(this.props.order_status.shipped).length > 0 ?
                                        Object.values(this.props.order_status.shipped).map((s, idx) =>
                                            <div className="cell small-4" id="shipped">
                                                {this.renderShipped(s, idx)}
                                            </div>
                                        )
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderShipped(s, idx) {
        return (
            <div key={idx}>
                {this.renderShippedItems(s.items)}
                {this.renderShippedInfo(s.date_shipped, s.shipping_tracking_id)}
                {s.tracking_numbers.length > 0 ? this.renderPromostandardsShippedInfo(s.tracking_numbers) : null}
            </div>
        );
    }

    renderShippedItems(items) {
        return (
            Object.values(items).map((i, idx) =>
                <h3 key={idx}>{i.product_name}</h3>
            )
        );
    }

    renderShippedInfo(date_shipped, tracking_id) {
        return [
          <p>{`Shipped Date: ${parseMysqlDate(date_shipped)}`}</p>,
          <p>
            Tracking ID: &nbsp;
            {(tracking_id || '')
              .split(",")
              .map((id) => id.trim())
              .filter((id) => !!id)
              .map((id, i) => <a key={id} target="_blank" href={`http://google.com/search?q=${id}`}>{i > 0 ? `, ` : null}{id}</a>)}
          </p>
        ];
    }

    renderPromostandardsShippedInfo(tracking_numbers) {
        return (
            <p><small>Additional Tracking IDs:&nbsp;
                {tracking_numbers.map((t, idx) =>
                <a key={idx} target="_blank" href={`http://google.com/search?q=${t}`} style={{ 'color': 'red' }}>{idx > 0 ? `, ` : null}{t}</a>
            )}
            </small></p>
        );
    }

    showEmailPopup(email) {
        this.setState({ email_popup: { show: 1, email: email } });
    }

    renderEmailPopup() {
        let email = this.state.email_popup.email;
        let sender = email.sender || {};

        return (
            <div className="popup-dark">
                <a href="" className="close-popup" onClick={e => { e.preventDefault(); this.closeFormPopup('email_popup'); }}>×</a>
                <div className="popup-frame" style={{ background: 'white', overflowY: 'auto' }}>
                    <div className="grid-x email-line-first">
                        <div className="cell small-3">
                            From:
                        </div>
                        <div className="cell small-6">{this.getEmailFrom(email)}</div>
                    </div>
                    <div className="grid-x email-line">
                        <div className="cell small-3">
                            To:
                        </div>
                        <div className="cell small-6">
                            {email.email_to_recipients}
                        </div>
                    </div>
                    <div className="grid-x email-line">
                        <div className="cell small-3">
                            Subject:
                        </div>
                        <div className="cell small-6">
                            {email.subject}
                        </div>
                    </div>
                    <div className="grid-x email-line">
                        <div className="cell small-3">
                            Date Sent:
                        </div>
                        <div className="cell small-6">
                            {moment(email.date_created).format('MMMM D, YYYY h:m A')}
                        </div>
                    </div>
                    <div className="grid-x email-line">
                        <div className="cell small-3">
                            CC:
                        </div>
                        <div className="cell small-6">
                            {email.email_cc_recipients}
                        </div>
                    </div>
                    <div className="grid-x email-line">
                        <div className="cell small-3">
                            Request Read Receipt:
                        </div>
                        <div className="cell small-6">
                            {email.receipt_requested == 1 ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div className="email-line">
                        {!email.email_body_html && !email.email_body_text ? null :
                            email.email_body_html ?
                                <Iframe srcdoc={email.email_body_html} />
                                :
                                <div style={{ margin: 10 }}>
                                    {_.map(email.email_body_text.split('\n'), (line) => <div>{line}</div>)}
                                </div>
                        }
                        {(email.attachments || []).length > 0 ?
                            <div className="grid-x email-line">
                                <div className="cell small-3">Attachments: </div>
                                {_.map(email.attachments, ({
                                    file_id, file_display_name, file_name_original, file_size, s3
                                }, i) => {
                                    return (
                                        <div className="cell small-6">
                                            <a key={i} style={{ display: 'block' }} href={+s3 === 1 ?
                                                `http://commonsku-attachments.s3-website-us-east-1.amazonaws.com/${file_id}`
                                                :
                                                getAbsoluteUrl(file_name_original)} target="_blank">{file_display_name} ({(file_size / 1000).toFixed(2)}k)
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                            : null}
                    </div>
                    {email.email_form_html ? <Iframe srcdoc={email.email_form_html} /> : null}
                </div>
            </div>
        );
    }

    renderPresentationComment(m) {
        const avatar = this.returnAvatar(m);
        const text = this.returnText(m.comment || '');
        const forms = Object.values(this.props.presentations).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {});

        return (
            <div key={m.presentation_comment_id} className="msg" id={m.rep_comment == 0 ? 'client' : null} onClick={e => { e.preventDefault(); this.showFormPopup('PRESENTATION', forms, 'Presentation'); }}>
                {m.rep_comment == 0 ? <img src="/images/portal/presentation.png" className="img-badge" /> : null}
                <h3><a><span className="name">{m.rep_comment == 1 ? `${m.actor.user_first_name} ${m.actor.user_last_name}` : m.commenter_name}</span></a> commented on the presentation</h3>
                <a><h3 className="product-title">{m.item.item_name}</h3></a>
                <div className="row comment-container">
                    <div className="product-img">
                        <a href=""><img src={m.item.file ? getAbsoluteUrl(m.item.file.medium) : '/images/404.png'} /></a>
                    </div>
                    <div className="comment-info">
                        <p dangerouslySetInnerHTML={{ __html: text }}></p>
                        <i>{formatFriendlyDateTime(m.latest_update)}</i>
                    </div>
               </div>
            </div>
        );
    }

    renderComment(m) {
        const avatar = this.returnAvatar(m);
        const text = this.returnText((m.text || ''));
        const isProof = m.details_type === 'PROOF-COMMENT';
        const forms = Object.values(this.props.proofs).reduce((o, p) => { if (p.form_number) { o[p.form_number] = p; } return o; }, {});
        const name = m.actor ? `${m.actor.user_first_name} ${m.actor.user_last_name}` : m.commenter_name;
        const title = isProof && m.status_name ? `Proof (${m.status_name}) by ${name}`
                : isProof && !m.status_name ? `Proof comment by ${name}`
                    : `Comment by ${name}`;

        return (
          <div
            key={m.comment_id}
            className="msg"
            id={!m.commenter_id ? 'client' : null}
            onClick={e => {
              e.preventDefault();
              if (isProof) {
                this.showFormPopup('PROOF', forms, 'Proof Approval');
	      }
	    }}
          >
               {isProof && m.status_name && m.status_name === 'Client Approved' ? <img src="/images/portal/proofapproval.png" className="img-badge" /> : null}
               <a href=""><h2 className="title-link">{title}</h2></a>
               {m.file ?
                    <div className="row comment-container">
                        <div className="product-img">
                            <a href=""><img src={m.file ? getAbsoluteUrl(m.file.medium) : '/images/404.png'} /></a>
                            <i>{m.file.file_display_name}</i>
                        </div>
                        <div className="comment-info">
                            <p dangerouslySetInnerHTML={{ __html: text }}></p>
                            <i>{formatFriendlyDateTime(m.latest_update)}</i>
                        </div>
                    </div>
                :

                    <div>
                        <p dangerouslySetInnerHTML={{ __html: text }}></p>,
                        <i>{formatFriendlyDateTime(m.latest_update)}</i>
                    </div>
                }
            </div>
        );
    }

    renderClientNote(m) {
        const avatar = this.returnAvatar(m);
        const text = this.returnText(m.text || m.message_text || '');
        const approval_regex = /^Order approved by/;
        const change_regex = /^Change requested by/;
        if (text.match(approval_regex) || text.match(change_regex) || m.details_type === 'PAYMENT') {
            return (
                <div key={m.message_id} className="msg" id="client">
                    <img src="/images/portal/salesorder.png" className="img-badge" />
                    <a><h2 className="title-link" style={{ display: 'inline-block' }}>{m.details_type === 'PAYMENT' ? m.description : `${m.details_parent_order_type} #${m.details_parent_form_number}`}</h2></a>
                    <p dangerouslySetInnerHTML={{ __html: text }}></p>
                    <i>{formatFriendlyDateTime(m.latest_update)}</i>
                </div>
            );
        } else {
            return null;
        }
    }

    tileIsHidden(t) {
        return t.icon == 'hidden' || (
            ['PRESENTATION', 'ESTIMATE'].indexOf(t.key) !== -1 && _.get(_.first(_.values(t.forms)), 'status_name', '') === 'Open'
        );
    }

    render() {
        const {
            job,
            client,
            tenant,
            user_id,
            presentations,
            estimates,
            sales_orders,
            deposit_invoices,
            order_status,
            proofs,
            invoices,
            feedbacks,
            unused,
            messages,
            onAddPortalComment,
            onDeletePortalComment,
            onSyncMessages,
            onSyncTiles,
        } = this.props;
        let background_url = job.job_template ? getAbsoluteUrl(`${job.job_template.order_templates[0].header.file_name_headers}`) :
            job.header ? job.header.file_name_headers :
                tenant.header ? tenant.header.file_name_headers :
                    getAbsoluteUrl('/images/favicons/favicon-96x96.png');

        return (
            <div className="portal">
                {this.state.form_popup.show ? this.renderFormPopup() : null}
                {this.state.multi_popup.show ? this.renderMultiPopup() : null}
                {this.state.show_order_status_popup ? this.renderOrderStatusPopup() : null}
                {this.state.email_popup.show ? this.renderEmailPopup() : null}
                {this.state.show_name_email_popup ?
                    this.renderNameEmailPopup()
                    : null}
                <div data-sticky-container>
                    <div className="title-bar" data-options="marginTop:0;" style={{ backgroundImage: `url('${background_url}')`, width: '100%' }}>
                        <div className="darken">
                            <div className="hdr-logo">
                                <img src={tenant.company_contact.avatar ? getAbsoluteUrl(tenant.company_contact.avatar.small) : getAbsoluteUrl('/images/banner.png')} />
                            </div>
                            <div className="hdr-info">
                                <h1>{client.client_name} - Project #{job.job_number} {job.job_name}</h1>
                                <p>{tenant.tenant_name}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="project-tiles column large-4">
                        <h1>Project Status  {this.renderPageViewCount()}</h1>
                        <div className="grid-y">
                            {this.state.tiles.map((t, idx) =>
                                t.icon && (['SALES ORDER', 'ORDER STATUS', 'INVOICE'].indexOf(t.key) !== -1 || t.icon !== 'hidden' && ['PRESENTATION', 'ESTIMATE', 'DEPOSIT', 'PROOF', 'FEEDBACK'].indexOf(t.key) !== -1) ?
                                    <div key={idx} className="cell small-4">
                                        <a onClick={e => {
                                                e.preventDefault();
                                                if(!this.tileIsHidden(t)) {
                                                    if(t.key === 'ORDER STATUS') {
                                                        this.updatePageClickCount(t.key); // only do this for order status
                                                        this.showOrderStatusPopup();
                                                    } else {
                                                        this.showFormPopup(t.key, t.forms, t.name);
                                                    }
                                                    // this.updatePageClickCount(t.key);
                                                }
                                            }}>
                                            <div className="tile" id={this.tileIsHidden(t) ? 'hidden' : t.icon}>
                                                <img src={t.img_src} />
                                                <p>{t.name} </p>
                                                {this.renderPageClickCount(t.key)}
                                            </div>
                                        </a>
                                    </div>
                                : null
                            )}
                        </div>
                    </div>
                </div>
                <div className="discussion column large-8">
                    <h1>Project Activities</h1>
                    <div id="messages">
                        {this.renderMessages()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        presentations: state.entities.presentations,
        estimates: state.entities.estimates,
        sales_orders: state.entities.sales_orders,
        deposit_invoices: state.entities.deposit_invoices,
        order_status: state.entities.order_status,
        proofs: state.entities.proofs,
        invoices: state.entities.invoices,
        feedbacks: state.entities.feedbacks,
        unused: state.entities.unused,
        messages: Object.values(state.entities.messages).filter(m => m.parent_id === null || m.parent_id === m.message_id).sort((a, b) => b.latest_update - a.latest_update),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddPortalComment: (comment, isPublic) => {
            dispatch(addPortalComment(comment, isPublic));
        },
        onDeletePortalComment: (comment_id) => {
            dispatch(deletePortalComment(comment_id));
        },
        onSyncMessages: messages => dispatch(syncMessages(messages)),
        onSyncTiles: portal => dispatch(syncTiles(portal)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPortal);
