import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ClientPortal from '../components/ClientPortal';

import createPopup from '../popup-factory';

class PortalApp extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { job, client, tenant, user_id, query_params } = this.props;

        return (
            <ClientPortal job={job} client={client} tenant={tenant} user_id={user_id} query_params={query_params} />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let job = state.entities.job[Object.keys(state.entities.job)[0]];

    return {
        job: job,
        client: state.entities.client[job.account_id],
        tenant: state.entities.tenant,
        user_id: state.identity ? state.identity.user_id : null,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PortalApp);
