import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";

import { reducers } from '../store/configurePortalStore';
import withReducers from '../store/withReducers';
import PortalApp from '../containers/PortalApp';
import NoMatch from './NoMatch';
import ErrorBoundary from './ErrorBoundary';

const AppRoute = React.memo(({ show }) => {
  const params = useParams();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (show) {
    return <PortalApp query_params={{ ...queryParams, ...params }} location={location} />;
  }

  return <NoMatch location={location} />;
});

const PortalRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/portal/:job_id"
        element={<AppRoute show />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

const mapStateToProps = (state) => ({});

export default withReducers(connect(mapStateToProps)(PortalRouter), reducers);
